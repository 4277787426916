<template>
  <div class="menu">
    <div class="list">
      <div v-for="item in menuList" :key="item.id" class="item" @click="goLink(item)">
        <div class="icon" :class="$route.name == item.name ? item.name + '_h': item.name ">
          <span v-if="item.isMsg && count" class="crile" />
        </div>
        <div class="title" :class="{ active: $route.name == item.name }">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import disp from '@/utils/broadcast'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      menuList: [
        { id: 1, title: '首页', name: 'location' },
        { id: 2, title: '消息', name: 'message', isMsg: true },
        { id: 3, title: '我的', name: 'user' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'count'
    ])
  },
  mounted() {
    disp.on('em.unreadspot', function(res) {
      console.log(res, '-------222------')
    })
  },
  methods: {
    goLink(item) {
      this.$router.push({ name: item.name })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .list {
    display: flex;
    width: 100%;
    padding: 10px 0 5px 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    .item {
      display: flex;
      align-items: center;
      flex-flow: column;
      flex: 1;
      text-align: center;
      .icon {
        position: relative;
        width: 24px;
        height: 24px;
        background-size: 100%;
        &.location {
          background-image: url('~@/assets/home-grey@2x.png')
        }
        &.location_h {
          background-image: url('~@/assets/home-color@2x.png')
        }
        &.message {
          background-image: url('~@/assets/notice-grey@2x.png')
        }
        &.message_h {
          background-image: url('~@/assets/notice-color@2x.png')
        }
        &.user {
          background-image: url('~@/assets/mine-grey@2x.png')
        }
        &.user_h {
          background-image: url('~@/assets/mine@2x.png')
        }
        .crile {
          position: absolute;
          right: -4px;
          top: 0;
          width: 8px;
          height: 8px;
          border-radius: 100px;
          border: 1px solid #fff;
          background: #FF5E2A;
        }
      }
      .title {
        padding-top: 5px;
        font-size: 12px;
        color: #777777;
        &.active {
          color: #FF7700;
        }
      }
    }
  }
}
</style>
